<template>
  <div class="h-100">
    <FormQuestion
      v-for="question in filteredQuestions"
      :key="question.id"
      :question="question"
      :question-answers="getAnswers(question.id, question)"
      :full-question-answers="filterQuestionAnswers(question.id)"
      :is-collapsed="isOpen"
      :current-tab="examTab"
      :stream-id="streamId"
      :form="form"
      :is-reset-grouped="!!form.reset_grouped"
      :answer-hidden="hideCorrectAnswers"
    >
      <p
        v-show="
          !alwaysShowQuestions &&
            examTab === 'open' &&
            !showManually(question, DISPLAY_ACTION_SHOW_QUESTION)
            && !question.answered &&
            !question.retry_answered"
        class="pr-4"
      >
        {{ $t('accreditation.timeLeft') + countDownQuestion(question) }}
      </p>
    </FormQuestion>

    <div
      v-if="!filteredQuestions.length && examTab === 'completed'"
      class="is-flex is-flex-direction-column ion-justify-content-center ion-align-items-center h-100"
    >
      <ion-card>
        <ion-text color="light">
          <p class="fz-18 text-placeholder ion-text-center">
            {{ $t('accreditation.completedTabPlaceholder') }}
          </p>
        </ion-text>
      </ion-card>
    </div>
    <div
      v-else-if="!filteredQuestions.length && examTab === 'open'"
      class="is-flex is-flex-direction-column ion-justify-content-center ion-align-items-center h-100"
    >
      <ion-card class="ion-text-center">
        <ion-text v-if="filteredQuestions.length === 0">
          <p class="fz-18 text-placeholder">
            {{ $t('accreditation.noQuestions') }}
          </p>
        </ion-text>
        <div
          v-if="!questionsResult.length"
          class="is-flex ion-justify-content-center ion-padding-top"
        >
          <ion-back-button
            :default-href="`/course/${courseId}?s=${streamId}`"
            :icon="arrowBackOutline"
            :text="$t('commonKeys.back')"
            color="light"
          />
        </div>
      </ion-card>
    </div>
  </div>
</template>

<script>
import FormQuestion from '@/components/accreditation/FormQuestion';
import {computed} from '@vue/reactivity';
import {useStore} from 'vuex';
import {IonBackButton, IonButton, IonCard, IonIcon, IonText} from '@ionic/vue';
import {arrowBackOutline} from 'ionicons/icons';
import {DISPLAY_ACTION_SHOW_QUESTION} from "@/config/constants";
import {watch} from '@vue/runtime-core';
import {showByCurrentTime, showByPlayerTime, showManually} from "@/helpers/functions";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: 'AccreditationExam',
  components: {
    FormQuestion,
    IonCard,
    IonText,
    IonButton,
    IonIcon,
    IonBackButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    questionsResult: {
      type: Array,
      default() {
        return [];
      },
    },
    examTab: {
      type: String,
      default: 'open',
    },
    streamId: {
      type: [String, Number],
      default: '',
    },
    hideCorrectAnswers: {
      type: Boolean,
      default: false,
    },
    alwaysShowQuestions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:questionsCount', 'new-question', 'init:new-question'],
  setup(props, { emit }) {
    const store = useStore();

    const formQuestions = computed(() => store.state.questionnaires.formAccreditation.questions ?? []);
    const form = computed(() => store.state.questionnaires.formAccreditation);
    const courseId = computed(() => store.state.questionnaires.courseId);
    const filteredQuestions = computed(() => {
      const clonedQuestions = cloneDeep(formQuestions.value);
      const filteredFormQuestions = clonedQuestions.filter(question => {
        const answerObject = props.questionsResult.find(el => el.id === question.id);
        if (answerObject) {
          // for sorting;
          question.answered = answerObject.answered;
          question.retry_answered = answerObject.retry_answered;
        }
        return !!answerObject;
      }).sort((a, b) => a.position - b.position);

      // answered at the end
      filteredFormQuestions.sort(function (a, b) {
        return (a.answered === b.answered) ? 0 : a.answered ? 1 : -1;
      });

      // answered but with available attempts at top
      filteredFormQuestions.sort((a, b) => {
        const aHasRetry = a.answered && !a.is_correct && (!form.value.reset_grouped && a.attempts > 0);
        const bHasRetry = b.answered && !b.is_correct && (!form.value.reset_grouped && b.attempts > 0);
        return (aHasRetry === bHasRetry) ? 0 : aHasRetry ? -1 : 1;
      });

      if (props.examTab === 'open' && !props.alwaysShowQuestions) {
        return filteredFormQuestions.filter(question => showQuestion(question));
      }
      return filteredFormQuestions;
    });
    const pendingQuestionsCount = computed(() => filteredQuestions.value.filter(el => !el.answered).length);

    const playerCurrentTime = computed(() => store.state.player.trackInfo.trackCurrentTime);
    const currentTime = computed(() => store.state.app.currentTime);

    const filterQuestionAnswers = (questionId) => {
      return (props.questionsResult.find((question) => question.id === questionId) || {});
    };

    const getAnswers = (id, question) => {
      const findAnswers = filterQuestionAnswers(id);
      if (findAnswers.answers?.length) {
        if (question.type === 'multiple') {
          return findAnswers.answers.map((el) => el.id);
        } else {
          if (question.type === 'date' && findAnswers.answers[0]?.answer) {
            return formatDate(findAnswers.answers[0].answer);
          }
          return findAnswers.answers[0]?.answer || findAnswers.answers[0]?.id;
        }
      } else {
        if (question.type === 'date' || question.type === 'time') {
          return formatDate(question.answers[0]?.answer);
        } else if (question.type === 'multiple') {
          return [];
        } else if (question.type === 'scale') {
          return 1;
        }
      }
    };

    const formatDate = (date) => date.split('-').reverse().join('-');

    const showQuestion = (question) => {
      return (question.answered || question.retry_answered) ||
        showManually(question, DISPLAY_ACTION_SHOW_QUESTION) ||
        showByPlayerTime(question, DISPLAY_ACTION_SHOW_QUESTION, playerCurrentTime.value) ||
        showByCurrentTime(question, DISPLAY_ACTION_SHOW_QUESTION, currentTime.value);
    };

    const countDownQuestion = (question) => {
      const secondsLeft = showByPlayerTime(question, DISPLAY_ACTION_SHOW_QUESTION, playerCurrentTime.value) || showByCurrentTime(question, DISPLAY_ACTION_SHOW_QUESTION, currentTime.value);
      if (typeof secondsLeft === 'number') {
        return new Date(secondsLeft * 1000).toISOString().substr(11, 8);
      }
      return '';
    };

    watch(() => pendingQuestionsCount.value, (newValue, oldValue) => {
      emit('update:questionsCount', newValue.length);

      if ((newValue - oldValue) > 0) {
        emit('new-question', newValue);
      }

      if (newValue && typeof oldValue === 'undefined') {
        emit('init:new-question', newValue);
      }
    }, {
      immediate: true,
    });


    return {
      filterQuestionAnswers,
      getAnswers,
      filteredQuestions,
      formQuestions,
      arrowBackOutline,
      courseId,
      countDownQuestion,
      showManually,
      form,
      DISPLAY_ACTION_SHOW_QUESTION,
    };
  },
};
</script>

<style lang="scss" scoped>
  .text-placeholder {
    line-height: 1.5;
  }

  ion-back-button {
    --background: var(--ion-color-primary);
    --padding-start: 10px;
    --padding-end: 10px;
    --icon-font-size: 24px;
    --icon-padding-end: 5px;
  }
</style>
