<template>
  <div class="form-question ion-margin-bottom ion-padding-bottom">
    <p class="fz-14 fw-500 ion-margin-bottom">{{ question.question }}</p>
    <transition name="slide-in-down">
      <div v-show="isCollapsed || isClicked">
        <AppDatetimePicker
          v-if="question.type === 'date'"
          :model-value="result"
          presentation="date"
          id="date_"
          :class="{ 'non-clickable opacity-50': disabledViewInput || disabledInputs }"
          @update:modelValue="result = $event"
        />
        <AppDatetimePicker
          v-else-if="question.type === 'time'"
          :class="{ 'non-clickable opacity-50': disabledViewInput || disabledInputs }"
          presentation="time"
          id="time_"
          :model-value="timeValueResult"
          @update:modelValue="handleTimeChange"
        />

        <component
          v-else
          :is="getInlineComponent(question.type)"
          :items="question.answers"
          :value="result"
          item-name="answer"
          :app-select-style="{ background: 'var(--grey)', borderRadius: '6px' }"
          @input:update="result = $event"
          v-model="result"
          :class="{ 'non-clickable opacity-50': disabledViewInput || disabledInputs }"
        />
        <div
          v-show="currentTab === 'open'"
          class="is-flex ion-text-end ion-padding-top ion-justify-content-end ion-align-items-center"
        >
          <slot></slot>
          <SendButton
            :loading="isSending"
            :is_answered="fullQuestionAnswers.answered"
            :is_correct="!!fullQuestionAnswers.is_correct"
            :attempts="questionAttempts"
            :is_open="Boolean(question.accept_open_answer)"
            :answer-hidden="Boolean(question.answer_hidden) || answerHidden"
            :no-correct-answer="Boolean(question.no_correct_answer)"
            @disabled-update="disabledViewInput = $event"
            @submit="submitAnswer"
            @click:retry="() => {}"
          />
        </div>
      </div>
    </transition>
    <div
      v-show="!isCollapsed"
      class="is-flex ion-justify-content-between"
    >
      <div
        class="is-flex ion-align-items-center pointer"
        @click="isClicked = !isClicked"
      >
        <div :class="[isClicked ? 'rotate-180' : 'rotate-0']">
          <IonIcon
            :icon="chevronDownOutline"
            class="icon"
          />
        </div>
        <p class="pl-2 fz-14">{{ isClicked ? $t('accreditation.hide') : $t('accreditation.show') }}</p>
      </div>
      <div>
        <SendButton
          class="non-clickable"
          :text="getCompletedButtonText"
          :color="completedBtnColor"
          background="clear"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { IonRadio, IonCheckbox, IonDatetime, IonIcon, IonText, IonSpinner, modalController } from '@ionic/vue';
import AppSelect from '@/components/common/AppSelect.vue';
import AppInput from '@/components/common/AppInput.vue';
import AppCheckBoxGroup from '@/components/common/AppCheckBoxGroup.vue';
import AppRadioGroup from '@/components/common/AppRadioGroup.vue';
import AppRating from '@/components/common/AppRating.vue';
import AppRange from '@/components/common/AppRange.vue';
import AppDatetimePicker from '@/components/common/AppDatetimePicker.vue';
import SendButton from '@/components/accreditation/SendButton.vue';
import { computed, ref } from '@vue/reactivity';
import { chevronDownOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n/index';
import { useStore } from 'vuex';
import useRequestToast from '@/composables/useRequestToast';
import VerifyInformationModal from '@/components/accreditation/VerifyInformationModal';
import {
  DISPLAY_TYPE_MANUAL,
  DISPLAY_TYPE_CLOCK,
  DISPLAY_TYPE_VIDEO,
  DISPLAY_ACTION_SHOW_QUESTION,
} from "@/config/constants";
import { watch } from '@vue/runtime-core';
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";

export default {
  name: 'FormQuestion',
  components: {
    IonRadio,
    IonCheckbox,
    IonDatetime,
    AppSelect,
    AppInput,
    AppCheckBoxGroup,
    AppRadioGroup,
    AppRating,
    AppRange,
    IonIcon,
    IonText,
    SendButton,
    IonSpinner,
    AppDatetimePicker,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionAnswers: {
      type: [String, Number, Array],
      default: '',
    },
    fullQuestionAnswers: {
      type: Object,
      default() {
        return {};
      },
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
    currentTab: {
      type: String,
      default: 'open',
    },
    disabledInputs: {
      type: Boolean,
      default: false,
    },
    streamId: {
      type: String,
      default: '',
    },
    isResetGrouped: {
      type: Boolean,
      default: false,
    },
    answerHidden: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  setup (props) {
    const componentsData = [
      { type: 'open', component: 'AppInput' },
      { type: 'select', component: 'AppRadioGroup' },
      { type: 'dropdown', component: 'AppSelect' },
      { type: 'multiple', component: 'AppCheckBoxGroup' },
      { type: 'rating', component: 'AppRating' },
      { type: 'scale', component: 'AppRange' },
      { type: 'date', component: 'AppDatetimePicker' },
      { type: 'time', component: 'AppDatetimePicker' },
    ];
    const { t } = useI18n();
    const store = useStore();
    const { openWarnToast, openSuccessToast, openErrorToast, openToast } = useRequestToast();

    const result = ref(props.questionAnswers);
    const isClicked = ref(false);
    const completedBtnColor = ref('');
    const isSending = ref(false);
    const disabledViewInput = ref(false);


    const questionAttempts = computed(() => {
      return props.isResetGrouped ? 0: props.fullQuestionAnswers.attempts_left;
    });

    const getCompletedButtonText = computed(() => {
      if (props.fullQuestionAnswers.is_correct) {
        completedBtnColor.value = 'success';
        return t('accreditation.correctAnswer');
      }
      if (props.fullQuestionAnswers.is_correct === null) {
        completedBtnColor.value = 'light';
        return t('accreditation.sentAnswer');
      }
      completedBtnColor.value = 'danger';
      return t('accreditation.wrongAnswer');
    });

    const getOpenButtonText = computed(() => {
      if (props.fullQuestionAnswers.answers?.length) {
        return t('accreditation.answerResend') + ` (${props.fullQuestionAnswers.attempts_left})`;
      }
    });

    const profileDetails = computed(() => store.getters['profile/getProfileDetails']);
    const user = computed(() => store.getters['authentication/getUser']);
    const userProfessions  = computed(() => store.getters['profile/getUserProfessions']);
    const playerCurrentTime = computed(() => store.state.player.trackInfo.trackCurrentTime);

    const getInlineComponent = (type) => {
      return componentsData.find(component => component.type === type)?.component || '';
    };

    const getAnswers = () => {
      if (Array.isArray(result.value)) {
        return result.value.map(el => {
          return {id: el};
        });
      }
      if (props.question.type === 'select' || props.question.type === 'dropdown') {
        return [{id: result.value}];
      }
      if (props.question.type === 'date') {
        return [{answer: result.value.slice(0, 10).split('-').reverse().join('-')}];
      }
      return [{answer: result.value}];
    };

    const submitAnswer = () => {
      isSending.value = true;
      const profileData = {
        first_name: profileDetails.value.first_name,
        last_name: profileDetails.value.last_name,
        insertion: profileDetails.value.insertion,
        email: user.value.email,
        professions: userProfessions.value.map(profession => ({ id: profession.profession_id })),
        update_accreditation_user_data_only: false,
        stream_id: props.streamId,
      };
      store.dispatch('questionnaires/sendAnswer', {
        questionId: props.fullQuestionAnswers.id,
        data: { answers: getAnswers(), ...profileData },
      }).then((res) => {
        handleAnswerToast(res);
        isSending.value = false;
        showPopUpIfPassedExam(res);
        refetchAnswersIfPassedExam(res);
        if (res.max_attempts_left !== undefined) {
          store.commit('questionnaires/updateFormAttempts', {
            newAttempts: res.max_attempts_left,
          });
        }
      }).catch((err) => {
        if (err?.response?.data?.errors?.answers) {
          openWarnToast(err?.response?.data?.errors?.answers);
        } else {
          openErrorToast(err);
          openModal(VerifyInformationModal, 'verify-information-modal');
        }
        isSending.value = false;
      });
    };

    const showPopUpIfPassedExam = (data) => {
      const isCompletedAllQuestion = data.medicast_total_questions_count === data.medicast_user_answered_questions_count;

      const passed = data.user_score_percentage >= data.passing_score_percentage;

      const passedFinal = isCompletedAllQuestion && passed;
      const wasShown = store.state.questionnaires.examPassedPopupShown;

      if (passedFinal && !wasShown) {
        const showCertificateMessage = t('streams.examPassedPopUpCertificate');
        const hideCertificateMessage = t('streams.examPassedPopUp');
        const message = props.form.allow_certificate ? showCertificateMessage : hideCertificateMessage;

        store.commit('questionnaires/setExamSuccessPopUpState', true);
        openSuccessToast(message);
      }
    };

    const refetchAnswersIfPassedExam = (data) => {
      if (!props.isResetGrouped) {
        return;
      }
      const completed = data.total_questions_count === data.user_answered_questions_count;
      const passed = completed && (data.user_score_percentage >= data.passing_score_percentage);

      if (!passed) {
        return;
      }
      store.dispatch(
        'questionnaires/getUserResponses',
        props.form.id,
      );
    };

    const handleAnswerToast = (answer) => {
      if (answer.is_correct === false) {
        openWarnToast(t('accreditation.wrongAnswer'));
      } else if (answer.is_correct) {
        openSuccessToast(t('accreditation.correctAnswer'));
      } else {
        openToast({
          message: t('accreditation.answerSent'),
          color: 'light',
        });
      }
    };

    const openModal = async (componentName, componentClass) => {
      const modal = await modalController.create({
        component: componentName,
        cssClass: componentClass,
        componentProps: {
          streamId: +props.streamId,
        },
      });
      await modal.present();
    };

    const handleTimeChange = (event) => {
      const date = new Date(event);
      const hh = date.getHours().toString().padStart(2, '0');
      const mm = date.getMinutes().toString().padStart(2, '0');
      const hh2 = date.getUTCHours().toString().padStart(2, '0');
      const mm2 = date.getUTCMinutes().toString().padStart(2, '0');
      const time = `${hh}:${mm}`;
      const time2 = `${hh2}:${mm2}`;
      if (event.endsWith('Z')) {
        result.value = time2;
      } else {
        result.value = time;
      }
    };

    const timeValueResult = computed(() => {
      if (props.question.type !== 'time') {
        return null;
      }
      let timeString = '10:00';
      if (result.value && typeof result.value === 'string') {
        timeString = result.value;
      }
      const [hours, minutes] = timeString.split(':');
      const date = new Date();

      date.setUTCHours(+hours);
      date.setUTCMinutes(+minutes);

      //   const offset = date.getTimezoneOffset();
      // date.setMinutes(date.getMinutes() - offset)

      //  console.log(offset)
      return date.toISOString();
    });

    // watch(() => props.questionAnswers, (newValue) => {
    //   const newValueCopy = cloneDeep(newValue);
    //   const oldValueCopy = cloneDeep(result.value);
    //   console.log('watcher', newValueCopy, oldValueCopy);
    //   if (!isEqual(newValueCopy, oldValueCopy)) {
    //     result.value = newValue;
    //   }
    // });

    return {
      getInlineComponent,
      result,
      chevronDownOutline,
      isClicked,
      getCompletedButtonText,
      getOpenButtonText,
      completedBtnColor,
      submitAnswer,
      isSending,
      playerCurrentTime,
      handleTimeChange,
      timeValueResult,
      questionAttempts,
      disabledViewInput,
    };
  },
};
</script>

<style lang="scss" scoped>
  ion-datetime, ion-select {
    background: var(--grey);
    border-radius: 4px;
  }
  ion-spinner {
    position: absolute;
  }
</style>
