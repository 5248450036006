<template>
  <div class="ion-margin">
    <template v-if="!progressOnly">
      <div
        v-if="userResults.user_answered_questions_count"
        class="exam-progress is-flex"
      >
        <div
          class="wrong-bar is-flex ion-align-items-center ion-justify-content-center bar-transition"
          :style="{ width: `${wrongScore}%`}"
        >
          <p v-if="wrongScore">{{ wrongScore + '%' }}</p>
        </div>
        <div
          class="success-bar is-flex ion-align-items-center ion-justify-content-center bar-transition"
          :style="{ width: `${successScore}%`}"
        >
          <p v-if="successScore">{{ successScore + '%' }}</p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="exam-progress is-flex">
        <div
          class="is-flex general-bar ion-align-items-center ion-justify-content-center bar-transition"
          :style="{ width: `${answeredPercentage}%`}"
        >
          <div
            v-if="answeredPercentage"
            class="ion-text-center"
          ></div>
        </div>
      </div>
    </template>
    <ion-text
      class="ion-text-right"
      color="light"
    >
      <p class="pt-1">{{ progressCount }}</p>
    </ion-text>
  </div>
</template>

<script>
import {computed} from '@vue/reactivity';
import {IonText} from '@ionic/vue';
import {useI18n} from 'vue-i18n/index';

export default {
  name: 'ExamProgress',
  components: {
    IonText,
  },
  props: {
    userResults: {
      type: Object,
      required: true,
    },
    progressOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const { t } = useI18n();

    const wrongScore = computed(() => {
      const result = props.userResults.user_answered_questions_count * 100 / props.userResults.total_questions_count - props.userResults.user_score_percentage;
      return result ? result.toFixed(2): 0;
    });
    const successScore = computed(() => {
      const result = props.userResults.user_score_percentage;
      return result ? result.toFixed(2): 0;
    });
    const progressCount = computed(() => {
      return props.userResults.medicast_user_answered_questions_count + '/' + props.userResults.medicast_total_questions_count + ' ' + t('accreditation.questionAnswered');
    });

    const answeredPercentage = computed(() => {
      const result = props.userResults.medicast_user_answered_questions_count * 100 / props.userResults.medicast_total_questions_count;
      return result ? result.toFixed(2): 0;
    });

    return {
      wrongScore,
      successScore,
      progressCount,
      answeredPercentage,
    };
  },
};
</script>

<style lang="scss" scoped>
  .exam-progress {
    height: 40px;
    border-radius: 6px;
    overflow: hidden;
    background: var(--grey);
    color: var(--white);
  }
  .wrong-bar {
    background: var(--ion-color-danger);
  }
  .success-bar {
    background: var(--ion-color-success);
    border-radius: 0 6px 6px 0 ;
  }
  .general-bar {
    background: var(--ion-color-light);
    border-radius: 0 6px 6px 0 ;
    color: var(--ion-color-dark);
  }
  .bar-transition {
    transition: width 0.5s;
  }
</style>
