<template>
  <div class="app-range">
    <IonRange
      min="1"
      max="10"
      snaps
      mode="md"
      v-model="range"
    />
    <ul class="num-list is-flex ion-justify-content-between">
      <li
        v-for="num in 10"
        :key="num"
      >
        {{ num }}</li>
    </ul>
  </div>
</template>

<script>
import { IonRange } from '@ionic/vue';
import useModelWrapper from "@/composables/useModelWrapper";

export default {
  name: 'AppRange',
  components: {
    IonRange,
  },
  props: {
    modelValue: {
      type: [String, Number, Array],
      required: false,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup (props, { emit }) {
    return { range: useModelWrapper(props, emit) };
  },
};
</script>

<style lang="scss" scoped>
  ion-range {
    padding-bottom: 0;
    padding-top: 0;
  }
  ion-range::part(tick), ion-range::part(tick-active) {
    --bar-height: 6px;
    border-radius: 50%;
    background: var(--bar-background-active);
  }

  .num-list {
    list-style-type: none;
    padding: 0 0px 0 7px;
    margin: 0;

    li {
      width: 20px;
      text-align: center;
    }
  }
</style>