<template>
  <ion-page v-if="!isError">
    <ion-header>
      <ion-toolbar>
        <ion-buttons
          slot="start"
          @click="$store.commit('player/setPlayerCollapse', false)"
        >
          <ion-back-button
            :text="$t('examLeaveModal.leaveExam')"
            :default-href="`/course/${courseId}?s=${$route.params.streamId}`"
          />
        </ion-buttons>
        <ion-title>{{ $t('accreditation.pageHeader') }}</ion-title>
      </ion-toolbar>
      <div
        v-if="!isLoading && formType === 'accreditation'"
        class="tablet-centered"
      >
        <EpisodePreview
          :title="currentEpisode.title"
          :subtitle="currentEpisode.description"
          :podcast-media="currentEpisode.media"
          :course-media="currentCourse.media"
          class="has-text-white ion-padding-horizontal"
        />

        <div>
          <ExamProgress
            :user-results="userResults"
            :progress-only="true"
          />
        </div>
        <div class="is-flex ion-justify-content-end">
          <ion-button
            v-if="examState.isCompletedAllQuestion && showEmailCertificate"
            class="is-relative"
            color="primary"
            mode="ios"
            @click="emailCertificate"
          >
            <ion-icon
              slot="end"
              :icon="mailOutline"
            />
            {{ $t('examLeaveModal.emailCertificate') }}
          </ion-button>
          <ion-button
            v-if="showRetryFormBtn"
            class="is-relative"
            color="warning"
            mode="ios"
            :disabled="isResettingFormLoader"
            @click="retryForm"
          >
            {{ $t('streams.retryForm') }}
          </ion-button>
        </div>
      </div>
    </ion-header>

    <ion-content v-if="isLoading">
      <div
        class="is-flex ion-align-items-center ion-justify-content-center h-100"
      >
        <IonSpinner />
      </div>
    </ion-content>
    <ion-content
      v-else
      class="ion-padding-start ion-padding-end pt-5"
    >
      <div class="ion-padding-top tablet-centered h-100">
        <AccreditationExam
          :form="form"
          :questions-result="openQuestions"
          :stream-id="$route.params.streamId"
          :hide-correct-answers="hideCorrectAnswers"
          :key="resetExamComponentKey"
          :always-show-questions="formType !== 'accreditation'"
          v-model:questionsCount="openQuestionsCount"
          @new-question="onNewQuestion"
          @init:new-question="onNewQuestion"
        />
      </div>
    </ion-content>

  </ion-page>

  <ion-page v-else>
    <ion-content>
      <div class="is-flex is-flex-direction-column ion-justify-content-center h-100 ion-text-center">
        <h4 class="ion-padding-bottom">{{ $t('accreditation.errorData') }}</h4>
        <IonBackButton
          default-href="/main/home"
          text="Home"
          color="primary"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AccreditationExam from '@/components/accreditation/AccreditationExam';
import ExamProgress from '@/components/accreditation/ExamProgress';
import VerifyInformationModal from '@/components/accreditation/VerifyInformationModal';
import { mailOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n/index';
import useNotificationSound from "@/composables/sound/useNotificationSound";

import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonImg,
  IonBackButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonLoading,
  IonSpinner,
  modalController,
} from '@ionic/vue';
import { computed, onUnmounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { homeOutline } from 'ionicons/icons';
import isNil from "lodash/isNil";
import useRequestToast from "@/composables/useRequestToast";
import PodcastInfo from '@/components/common/PodcastInfo.vue';
import EpisodePreview from "@/components/player/EpisodePreview";


export default {
  layout: 'mobile-default',
  components: {
    EpisodePreview,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonImg,
    IonBackButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonLoading,
    IonSpinner,
    AccreditationExam,
    ExamProgress,
    PodcastInfo,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const { openSuccessToast, openErrorToast } = useRequestToast();
    const { playNotification: playNotificationSound } = useNotificationSound();

    const isLoading = ref(false);
    const isResettingFormLoader = ref(false);
    const currentTab = ref('open');
    const isError = ref(false);
    const openQuestionsCount = ref(0);
    const resetExamComponentKey = ref(0);
    const form = computed(() => store.state.questionnaires.formAccreditation);

    const profileDetailsFetched = computed(() => store.getters['profile/profileDetailsFetched']);
    const userAnswers = computed(() => store.state.questionnaires.userAnswers);
    const userResults = computed(() => store.state.questionnaires.userResults);
    const formType = computed(
      () => store.state.questionnaires.formAccreditation?.type,
    );
    const courseId = computed(() => store.state.questionnaires.courseId);

    const isExamResetTimesGrouped = computed(() => !!form.value?.reset_grouped);

    const examState = computed(() => {
      const formResults = userResults.value;
      const isCompleted = isNil(formResults.total_questions_count)
        ? null
        : formResults.total_questions_count === formResults.user_answered_questions_count;

      const isCompletedAllQuestion = isNil(formResults.medicast_total_questions_count)
        ? null
        : formResults.medicast_total_questions_count === formResults.medicast_user_answered_questions_count;

      const isPassed = isNil(formResults.user_score_percentage)
        ? null
        : formResults.user_score_percentage >= formResults.passing_score_percentage;

      return { isCompleted, isCompletedAllQuestion, isPassed };
    });

    const showRetryFormBtn  = computed(() => {
      if (!isExamResetTimesGrouped.value) {
        return;
      }

      const hasAttempts = form.value.attempts_left > 0;
      return examState.value.isCompleted && !examState.value.isPassed && hasAttempts;
    });

    const hideCorrectAnswers = computed(() => {
      return isExamResetTimesGrouped.value && (!(examState.value.isCompleted && examState.value.isPassed));
    });

    const scoreProgressOnly =  computed(() => {
      return isExamResetTimesGrouped.value && (!examState.value.isCompleted);
    });

    const openQuestions = computed(() => {
      return userAnswers.value;
    },
      // userAnswers.value.filter((answer) => !answer.answers.length || (answer.attempts_left && answer.is_correct === false)),
    );
    const completedQuestions = computed(() =>
      userAnswers.value.filter(
        (answer) =>
          answer.is_correct === true ||
          (!answer.attempts_left && !answer.is_correct) ||
          (answer.answers.length && answer.is_correct === null),
      ),
    );

    const showEmailCertificate = computed(() => examState.value.isPassed);

    const getData = async () => {
      isLoading.value = true;
      try {
        await store.dispatch('questionnaires/getForm', {
          streamId: route.params.streamId,
          formId: route.params.formId,
        });
        await store.dispatch(
          'questionnaires/getUserResponses',
          route.params.formId,
        );
        isLoading.value = false;
      } catch (error) {
        isError.value = true;
        isLoading.value = false;
      }
    };

    const openModal = async (componentName, componentClass) => {
      const modal = await modalController.create({
        component: componentName,
        cssClass: componentClass,
        componentProps: {
          streamId: +route.params.streamId,
        },
      });
      await modal.present();
    };

    const openPlayer = (id, course) => {
      const startAt = course.streams.findIndex((stream) => stream.id === id);
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
        collapse: true,
      });
    };

    getData().then(() => {
      if (!profileDetailsFetched.value && !isError.value) {
        store.dispatch('profile/setUserProfessions')
          .then(() => {
            openModal(VerifyInformationModal, 'verify-information-modal');
          });
        store.dispatch('course/setCourseData', courseId.value)
          .then((course) => {
            openPlayer(+route.params.streamId, course);
          });
      }
    });
    if (!store.state.app.currentTimeIntervalId) {
      store.dispatch('app/startUpdateCurrentTime');
    }

    const retryForm = async () => {
      isResettingFormLoader.value = true;
      try {
        await store.dispatch('questionnaires/resetUserAnswerLastAttempt', { formId: form.value.id });
        await store.dispatch('questionnaires/getExamResults', parseInt(route.params.streamId));
        store.commit('questionnaires/clearAccreditationAnswersOnRetry');

        resetExamComponentKey.value++;
        // eslint-disable-next-line no-empty
      } catch (e) {} finally {
        isResettingFormLoader.value = false;
      }
    };

    const emailCertificate = () => {
      store.dispatch('questionnaires/sendCertificateToUserEmail', form.value.id)
        .then(() => {
          openSuccessToast(t('profile.emailSentMessage'));
        }).catch((error) => {
          openErrorToast(error);
        });
    };

    const onNewQuestion = () => {
      store.commit('player/setPlayerCollapse', true);
      playNotificationSound();
    };


    const currentEpisode = computed(() => store.getters['player/getCurrentEpisode'] ?? {});
    const currentCourse = computed(() => store.state.player.currentCourse ?? { media: [] });

    onUnmounted(() => {
      store.commit('app/resetCurrentTimeIntervalId');
    });

    return {
      currentTab,
      openQuestions,
      completedQuestions,
      userAnswers,
      isLoading,
      isResettingFormLoader,
      userResults,
      formType,
      courseId,
      isError,
      homeOutline,
      openQuestionsCount,
      form,
      isExamResetTimesGrouped,
      scoreProgressOnly,
      examState,
      showRetryFormBtn,
      hideCorrectAnswers,
      showEmailCertificate,
      retryForm,
      emailCertificate,
      mailOutline,
      onNewQuestion,
      resetExamComponentKey,
      currentEpisode,
      currentCourse,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-header {
  background: var(--ion-background-color);
}
ion-segment-button {
  --color: var(--white);
}
ion-segment-button::part(indicator-background) {
  border-radius: 12px;
}
ion-label {
  font-size: 20px;
}
</style>
