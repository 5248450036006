<template>
  <div class="app-rating">
    <IonIcon
      v-for="rate in rating"
      :key="rate.id"
      size="large"
      :icon="star"
      class="pointer md icon-large hydrated px-3"
      :class="{ checked: rate.isChecked }"
      @click="starChecked(rate.id)"
    />
  </div>
</template>

<script>
import { star, starOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import { reactive } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';

export default {
  name: 'AppRating',
  components: {
    IonIcon,
  },
  props: {
    modelValue: {
      type: [String, Number, Array],
      required: false,
      default: '',
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const rating = reactive([
      { id: 1, isChecked: false },
      { id: 2, isChecked: false },
      { id: 3, isChecked: false },
      { id: 4, isChecked: false },
      { id: 5, isChecked: false },
    ]);

    const starChecked = (id) => {
      rating.forEach((rate) =>
        rate.id <= id ? (rate.isChecked = true) : (rate.isChecked = false),
      );
      onChange(id);
    };
    const onChange = (id) => {
      emit('update:modelValue', id);
    };

    onMounted(() => {
      starChecked(+props.modelValue);
    });

    return { star, starOutline, rating, starChecked };
  },
};
</script>

<style lang="scss" scoped>
.checked {
  color: var(--ion-color-primary);
}

.app-rating:hover > ion-icon {
  color: var(--ion-color-primary);
}
.app-rating > ion-icon:hover ~ ion-icon {
  color: var(--white);
}
</style>
