import { Howl } from 'howler';
let sound;
let retryCounter = 0;
const setNotificationSound = (onLoadCb) => {
  sound?.unload();
  sound = new Howl({
    src: ['/assets/sounds/notification.mp3'],
    onload() {
      retryCounter = 0;
      if (onLoadCb) {
        onLoadCb();
      }
    },
    onloaderror () {
      if (retryCounter++ > 3) {
        throw new Error('failed to load notification sound');
      } else {
        sound?.load();
      }
    },
    onplayerror  () {
      setNotificationSound();
    },
  });
};

setNotificationSound();

export default () => {
  const playNotification = ({ skipIsPlaying = true } = {}) => {
    if (!sound) {
      setNotificationSound(() => {
        playNotification();
      });
      return;
    }
    if (skipIsPlaying && sound?.playing()) { return; }
    sound?.play();
  };
  return { playNotification };
};
