<template>
  <div>
    <ion-item
      v-for="item in items"
      :key="item.id"
    >
      <ion-label>{{item.answer}}</ion-label>
      <ion-checkbox
        slot="start"
        :checked="isChecked(item.id)"
        @ionChange="onChange(item.id, $event)"
      />
    </ion-item>
  </div>
</template>

<script>
import { IonCheckbox, IonLabel, IonItem } from '@ionic/vue';
import {defineComponent} from 'vue';
import {ref} from "@vue/reactivity";
import {watchEffect} from "@vue/runtime-core";

export default defineComponent ({
  name: "AppCheckBoxGroup",
  components: {
    IonCheckbox,
    IonLabel,
    IonItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const internalCheckState = ref([]);
    internalCheckState.value.push(...props.modelValue);

    const isChecked = (id) => {
      return internalCheckState.value.includes(id);
    };

    const onChange = (itemId, event) => {
      console.log('changed', itemId,  internalCheckState.value);
      console.log('event', event);

      if (event.detail.checked) {
        internalCheckState.value.push(itemId);
      } else {
        internalCheckState.value = internalCheckState.value.filter(id => id !== itemId);
      }

      emit('update:modelValue', internalCheckState.value);
    };


    watchEffect(() => {
      internalCheckState.value = [...props.modelValue];
    });

    return {
      onChange,
      isChecked,
      internalCheckState,
    };
  },

});
</script>


<style scoped>
  ion-checkbox {
    --background: var(--grey);
    --border-radius: 4px;
    --size: 24px;
  }

  ion-label {
    background: var(--grey);
    padding: 10px;
    border-radius: 6px;
    margin-right: 0;
    white-space: normal !important;
    text-overflow: unset !important;
  }

  ion-item {
    --border-color: transparent;
  }
</style>
