<template>
  <ion-button
    class="is-relative"
    :disabled="loading || disabled || isButtonDisabled"
    :style="{ fontSize }"
    :color="buttonClass"
    :fill="background"
    @click="clickHandler"
  >
    {{ buttonText }}
    <IonSpinner v-if="loading"/>
  </ion-button>
</template>

<script>
import { IonButton, IonSpinner } from '@ionic/vue';

export default {
  name: 'SendButton',
  components: {
    IonButton,
    IonSpinner,
  },
  props: {
    fontSize: {
      type: String,
      default: '16px',
    },
    background: {
      type: String,
      default: 'solid',
    },
    textColor: {
      type: String,
      default: 'red',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    is_answered: {
      type: Boolean,
      default: false,
    },
    is_correct: {
      type: Boolean,
      default: false,
    },
    attempts: {
      type: Number,
      default: 1,
    },
    is_open: {
      type: Boolean,
      default: false,
    },
    noCorrectAnswer: {
      type: Boolean,
      default: false,
    },
    answerHidden: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['disabled-update', 'click:retry', 'submit'],

  data () {
    return {
      clearStateAfterRetry: false,
      finalStates: ['sent', 'correct', 'wrong'],
      buttonClasses: {
        send: 'primary',
        sent: 'light',
        correct: 'success',
        wrong: 'danger',
        retry: 'warning',
      },
    };
  },

  computed: {
    states () {
      return {
        send: !this.is_answered || this.clearStateAfterRetry,
        sent: this.is_answered && (this.is_open || this.noCorrectAnswer || this.answerHidden),
        correct: this.is_correct && !this.is_open && this.is_answered,
        wrong: this.is_answered && !this.is_open && !this.is_correct && this.attempts === 0,
        retry: this.is_answered && !this.is_open && !this.is_correct && this.attempts > 0 && !this.clearStateAfterRetry,
      };
    },
    buttonLabels () {
      return {
        send: this.$t('streams.send'),
        sent: this.$t('streams.sent'),
        correct: this.$t('streams.correct'),
        wrong: this.$t('streams.wrong'),
        retry: `${this.$t('streams.wrong')} ${this.$t('streams.retry')} - (${this.attempts})`,
      };
    },
    currentState () {
      for (const [key, value] of Object.entries(this.states)) {
        if (value) {
          return key;
        }
      }
      return Object.keys(this.states)[0];
    },
    buttonText () {
      return this.buttonLabels[this.currentState];
    },
    buttonClass () {
      return this.buttonClasses[this.currentState];
    },
    isButtonDisabled () {
      return this.finalStates.includes(this.currentState);
    },
  },

  watch: {
    isButtonDisabled: {
      immediate: true,
      handler () {
        this.$emit('disabled-update', this.isButtonDisabled);
      },
    },
  },

  methods: {
    clickHandler () {
      if (this.currentState === 'retry') {
        this.clearStateAfterRetry = true;
        this.$emit('click:retry');
        return;
      }

      if (!this.isButtonDisabled) {
        this.clearStateAfterRetry = false;
        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ion-button {
    max-width: 235px;
    height: auto;
    white-space: normal;
    --padding-top: 10px;
    --padding-bottom: 10px;
  }
   ion-spinner {
    position: absolute;
  }
</style>
