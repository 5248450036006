<template>
  <div>
    <ion-radio-group v-model="radioGroup">
      <ion-item
        v-for="item in items"
        :key="item.id"
      >
        <ion-label>{{ item.answer }}</ion-label>
        <ion-radio
          slot="start"
          :value="item.id"
        />
      </ion-item>
    </ion-radio-group>
  </div>
</template>

<script>

import { IonRadioGroup, IonRadio, IonLabel, IonItem } from '@ionic/vue';
import useModelWrapper from "@/composables/useModelWrapper";

export default {
  name: "AppRadioGroup",
  components: {
    IonRadioGroup, IonRadio, IonLabel, IonItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
  },
  setup (props, { emit }) {
    return {
      radioGroup: useModelWrapper(props, emit),
    };
  },
};
</script>

<style lang="scss" scoped>
  ion-radio {
    width: 24px;
    height: 24px;
  }

  ion-radio::part(container) {
    background: var(--grey);
    border-radius: 50%;
    width: 24px;
  }

  ion-label {
    background: var(--grey);
    padding: 10px;
    border-radius: 6px;
  }

  ion-item {
    --border-color: transparent;
    --inner-padding-end: 0;
  }
</style>